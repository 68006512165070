import { getPaletteColors } from '@om/template-properties/src/getPaletteColors';
import { createPaletteStyleElement, generatePalette } from '../editor/util';

export default {
  data: () => ({
    palette: [],
    scope: '.brand-themes-template-box',
    paletteRef: 'paletteDefinitions',
    styleElement: null,
  }),
  methods: {
    getPaletteColors,
    updatePalette(main) {
      this.palette = this.getPaletteColors(main);
      this.generatePaletteCSS();
    },
    generatePaletteCSS(color) {
      const palette = color ? this.getPaletteColors(color) : this.palette;
      const styleElement = this.getStyleElement();

      generatePalette(styleElement, palette, this.scope);
    },
    getStyleElement() {
      if (this.styleElement) return this.styleElement;
      const wrapper = this.$refs[this.paletteRef];
      this.styleElement = createPaletteStyleElement(wrapper, 'custom-colors');

      return this.styleElement;
    },
  },
};
