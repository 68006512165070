export default {
  props: {
    cache: { type: [Array, Object], default: null },
  },
  methods: {
    updateCache(value) {
      this.$emit('updateCache', value);
    },
  },
};
