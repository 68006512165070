const getAbTestTactic = () => {
  return {
    useCaseCollectionId: '64b93c2949a22f0028c7aab0',
    image: 'advanced-tactics/headline-ab-testing.png',
    content:
      'https://www.optimonk.com/tactics/landing-page-headlines-with-different-value-propositions/',
    selectType: 'start-DC-campaign',
  };
};

const getAdvancedTactics = () => {
  return [
    {
      name: {
        en: 'A/B test your website headline',
        hu: 'A/B teszteld weboldalad címsorát',
      },
      targeting: {
        en: 'Improve the conversion rate of your landing pages by A/B testing different headlines with different value propositions and finding the best one for your audience.',
        hu: 'Növeld a landoló odalak konverziós arányát azáltal, hogy A/B teszteled a különböző címsorokat különböző értékajánlatokkal, és találd meg a közönség számára legmegfelelőbbet.',
      },
      image: 'advanced-tactics/headline-ab-testing.png',
      content:
        'https://www.optimonk.com/tactics/landing-page-headlines-with-different-value-propositions/',
    },
    {
      name: {
        en: 'A/B test your website headline',
        hu: 'A/B teszteld weboldalad címsorát',
      },
      targeting: {
        en: 'Improve the conversion rate of your landing pages by A/B testing different headlines with different value propositions and finding the best one for your audience.',
        hu: 'Növeld a landoló odalak konverziós arányát azáltal, hogy A/B teszteled a különböző címsorokat különböző értékajánlatokkal, és találd meg a közönség számára legmegfelelőbbet.',
      },
      image: 'advanced-tactics/headline-ab-testing.png',
      content:
        'https://www.optimonk.com/tactics/landing-page-headlines-with-different-value-propositions/',
    },
    {
      name: {
        en: 'A/B test your website headline',
        hu: 'A/B teszteld weboldalad címsorát',
      },
      targeting: {
        en: 'Improve the conversion rate of your landing pages by A/B testing different headlines with different value propositions and finding the best one for your audience.',
        hu: 'Növeld a landoló odalak konverziós arányát azáltal, hogy A/B teszteled a különböző címsorokat különböző értékajánlatokkal, és találd meg a közönség számára legmegfelelőbbet.',
      },
      image: 'advanced-tactics/headline-ab-testing.png',
      content:
        'https://www.optimonk.com/tactics/landing-page-headlines-with-different-value-propositions/',
    },
    {
      name: {
        en: 'A/B test your website headline',
        hu: 'A/B teszteld weboldalad címsorát',
      },
      targeting: {
        en: 'Improve the conversion rate of your landing pages by A/B testing different headlines with different value propositions and finding the best one for your audience.',
        hu: 'Növeld a landoló odalak konverziós arányát azáltal, hogy A/B teszteled a különböző címsorokat különböző értékajánlatokkal, és találd meg a közönség számára legmegfelelőbbet.',
      },
      image: 'advanced-tactics/headline-ab-testing.png',
      content:
        'https://www.optimonk.com/tactics/landing-page-headlines-with-different-value-propositions/',
    },
  ];
};

module.exports = { getAbTestTactic, getAdvancedTactics };
