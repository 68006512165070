import { getAbTestTactic } from '@/config/staticTactics';
import GET_TACTIC_BASE_DATA from '@/graphql/GetTacticBaseData.gql';

const FIX_TACTIC_SELECT_TYPES = {
  DC: 'start-DC-campaign',
};

export default {
  data() {
    return {
      preparedFixTactic: null,
    };
  },
  computed: {
    getFixTacticOptions() {
      return { selectTypes: [FIX_TACTIC_SELECT_TYPES.DC] };
    },
    getFixTactic() {
      return this.preparedFixTactic;
    },
  },
  methods: {
    hasSelectHandlerForFixTactic(tactic) {
      return tactic?.selectType && this.getFixTacticOptions.selectTypes.includes(tactic.selectType);
    },
    selectFixTactic() {
      const selectType = this.getFixTactic?.selectType;
      if (selectType && selectType === FIX_TACTIC_SELECT_TYPES.DC) {
        this.$emit('createDynamicContent');
      }
    },
    async prepareFixTactic() {
      const tactic = getAbTestTactic();
      tactic.hasSelectHandler = this.hasSelectHandlerForFixTactic(tactic);
      if (!tactic.useCaseCollectionId) {
        this.preparedFixTactic = { ...tactic, _id: tactic.useCaseCollectionId };
        return;
      }
      const id = tactic.useCaseCollectionId;
      const tacticData = await this.getTacticData(id);
      if (!tacticData?.name) {
        return;
      }
      this.preparedFixTactic = {
        ...tactic,
        ...(tacticData || {}),
        _id: tactic.useCaseCollectionId,
      };
    },
    async getTacticData(id) {
      try {
        const {
          data: { useCase },
        } = await this.$apollo.query({
          query: GET_TACTIC_BASE_DATA,
          variables: {
            id,
          },
        });
        return useCase;
      } catch (e) {
        return null;
      }
    },
  },
};
